import { panelDataStyle, statsLabelsStyle } from '../view/TextStyles';

const COLUMN_LENGTH = 8;

export default class History {
    constructor(view, res) {
        this.content = view.getChildByName('history_lines', true);

        this._textures = {
            blue: res['history_blue'],
            orange: res['history_orange'],
            green: res['history_green']
        };

        this._lines = [];
    }

    add(data) {
        this._moveLinesDown();

        const drawNumber = data.draw;
        let result = data.result;

        const lineContainer = new PIXI.Container();

        lineContainer.position.set(67, 98)
        lineContainer.name = 'line_container';
        this.content.addChild(lineContainer);

        const index = result - 1
        let color = (index + Math.floor(index / 8)) % 2 === 0 ? 'blue' : 'orange';

        if (result === 25 || result === 0)
            color = 'green';

        if (result === 25)
            result = 'П/О';

        if (result === 0)
            result = 'Ш';

        const resultSprite = new PIXI.Sprite(this._textures[color]);

        resultSprite.x = -7;
        resultSprite.name = 'sadlfjdlsa';
        resultSprite.anchor.set(0.5);
        lineContainer.addChild(resultSprite);

        const resultText = new PIXI.Text(result, statsLabelsStyle);

        resultText.anchor.set(0.5);
        resultText.scale.set(0.5);
        resultSprite.addChild(resultText);

        const drawNumberText = new PIXI.Text(drawNumber, panelDataStyle);

        drawNumberText.x = -62
        drawNumberText.anchor.set(0, 0.5);
        drawNumberText.scale.set(0.5);
        lineContainer.addChild(drawNumberText);

        this._lines.push(lineContainer);
    }

    _moveLinesDown() {
        if (this._lines.length > 15)
            this.content.removeChild(this._lines.shift());

        this._lines.forEach(line => line.y -= 14);

        if (this._lines.length > 7)
            this._lines.slice().reverse()[7].position.set(0, 98);
    }
}