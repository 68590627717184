import { createMachine, createService } from '../fsm';
import ViewBuilder from '../display/ViewBuilder';

function *viewGeneratorFunc(view) {
    const nodes = [view];

    while (nodes.length > 0) {
        const v = nodes.shift();

        nodes.push(...v.children);
        yield v;
    }
}

const has = (obj, name) => Object.hasOwnProperty.call(obj, name);

export default class Scene extends PIXI.Container {
    constructor(view, resources, builder = new ViewBuilder()) {
        super();

        this.view = builder.build(view, resources);
        this.addChild(this.view);
    }

    getConfig() {
        return {};
    }

    getOptions() {
        return {};
    }

    map(s) {
        const set = {};

        for (const key in s)
            set[s[key]] = key;

        for (const obj of viewGeneratorFunc(this.view)) {
            if (has(set, obj.name)) {
                if (has(this, set[obj.name])) {
                    console.warn(`Scene already has property: ${set[obj.name]} mapped to ${obj.name}, override to new one`);
                }

                this[set[obj.name]] = obj;
            }
        }
    }

    get(name, view = this.view) {
        return view.getChildByName(name, true);
    }

    getMachine() {
        return createMachine(this.getConfig(), this.getOptions());
    }

    createService(options) {
        this.service = createService(this.getMachine(), options);
        return this.service;
    }

    resize() {
    }
}
