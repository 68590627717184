import { interpret } from 'xstate';

import { inspect } from '@xstate/inspect';
import { IS_PRODUCTION } from '../env';

if (!IS_PRODUCTION) {
    inspect({ iframe: false });
}

export { createMachine, assign, actions, send, sendParent } from 'xstate'
// export * from './betActions';

export const createService = (machine, options = {}) => {
    return interpret(machine, { ...options, devTools: !IS_PRODUCTION });
};

export * as px_context  from './context';
export * as px_actions  from './actions';
export * as px_configs  from './configs';
export * as px_guards   from './guards';
export * as px_services from './services';
