import { ViewBuilder, Scene } from 'core';
import gameSceneView from './view';
import game from './config/game';
import debug from 'core/debug';
import Cell from './components/Cell';
import History from './components/History';
import transport from '../../transport/transport';
import Stats from './components/Stats';
import Timer from './components/Timer';

export default class GameScene extends Scene {
    constructor(resources) {
        super(gameSceneView, resources);

        this.resources = resources;

        this.initAnims();
        this.createLinksForViewObjects();

        this.history = new History(this.view, this.resources);
        this.timer = new Timer(this.view);
        this.stats = new Stats(this.view);
        this.cells = [];

        this.createCells();

        debug(this.view);
    }

    getConfig() {
        return game;
    }

    getOptions() {
        return {
            actions: {
                allow: this.allow.bind(this),
                setHistory: this.setHistory.bind(this),
                setStats: this.setStats.bind(this),
                setCurrentDraw: this.setCurrentDraw.bind(this),
                forbid: this.forbid.bind(this)
            },
            services: {
                init: this.init.bind(this),
                draw: this.draw.bind(this),
            },
        };
    }

    async init() {
        this.transport = transport;

        const payload = await transport.requestPromise('roulette_list_get', { type: 'penalty', limit: 200 });
        const rouletteData = payload.roulettes[0];

        console.warn(rouletteData);
        this.rouletteId = rouletteData.roulette_id;

        transport.on('roulette_state_changed', payload => this.processMessage(payload));

        return rouletteData;
    }

    async draw(ctx, evt) {
        const result = evt.result;
        const draw = evt.draw;

        this.timer.start(ctx.rouletteData.other.time_calculate, 'РОЗЫГРЫШ: ')

        // const videoTexture = PIXI.Texture.from(`/assets/videos/${result}.mp4`);

        // this.video.texture = videoTexture;

        // const source = this.video.texture.baseTexture.resource.source;

        // source.play();

        // this.videoBackground.visible = true;

        // await new Promise(resolve => source.onended = resolve);

        // source.pause();
        // source.load();

        // this.videoBackground.visible = false;

        await this.addTween().duration(ctx.rouletteData.other.time_calculate - 2000).promise();

        this.history.add({ draw, result });
        this.stats.update(result);

        if (result > 24)
            return;

        this.cells[result].draw();
    }

    allow(ctx) {
        this.cells.forEach(cell => cell.reset());
        this.timer.start(ctx.rouletteData.other.time_allow - 2000, 'ПРИЕМ СТАВОК: ');
    }

    async forbid(ctx, evt) {
        await this.timer.start(ctx.rouletteData.other.time_forbid, 'ОЖИДАНИЕ РОЗЫГРЫША: ');
        this.service.send('CALCULATE', { draw: this.currentDraw, result: this.currentResult });
    }

    async showResultVideo(number) {
        const videoTexture = PIXI.Texture.from(`/assets/videos/${number}.mp4`);

        videoTexture.baseTexture.resource.autoPlay = false;
        this.video.texture = videoTexture;

        const source = this.video.texture.baseTexture.resource.source;

        source.onloadstart = () => console.warn('load started');
        source.onloadeddata = () => console.warn('loaded');
        source.load();

        await this.addTween().duration(7500).promise();

        source.play();

        this.videoBackground.visible = true;

        await new Promise(resolve => source.onended = resolve);

        this.videoBackground.visible = false;
    }

    initAnims() {
        const logoTextures = new Array(91).fill(0).map((_, i) => this.resources['l_' + i.toString().padStart(2, '0')]);

        this.logo = new PIXI.AnimatedSprite(logoTextures);
        this.logo.name = 'logo';
        this.logo.position.set(-119, -97);
        this.logo.anchor.set(0.5);
        this.logo.animationSpeed = 0.5;
        this.logo.play();
        this.view.addChild(this.logo);

        this.videoBackground = new PIXI.Graphics().beginFill(0x000000, 0.5).drawRect(-456 / 2, -202 / 2, 456, 202).endFill();

        this.videoBackground.name = 'video_background';
        this.videoBackground.position.set(-296 + 456 / 2, -110 + 202 / 2);
        this.videoBackground.visible = false;
        this.view.addChild(this.videoBackground);

        // const videoTexture = PIXI.Texture.from('/assets/videos/1.mp4');

        this.video = new PIXI.Sprite();
        this.video.name = 'video_sprite';
        this.video.anchor.set(0.5);
        this.video.scale.set(0.35);

        const watermark = new PIXI.Sprite(this.resources['watermark']);

        watermark.name = 'watermark';
        watermark.position.set(-319, 194);
        watermark.scale.set(2.9);
        this.video.addChild(watermark);

        this.videoBackground.addChild(this.video);
    }

    createCells() {
        this.cells.push(this.gatesGlow);

        for (let i = 0; i < 24; i++) {
            const cell = new Cell(this.resources, i + 1);

            cell.position.set(57 * (i % 8), 67 * Math.floor(i / 8));
            this.cellsContainer.addChild(cell);
            this.cells.push(cell);
        }

        this.gatesGlow.draw = () => this.gatesGlow.addTween().to({ alpha: 1 }).duration(2000).yoyo().loop();
        this.gatesGlow.reset = () => this.gatesGlow.removeTweens();
    }

    createLinksForViewObjects() {
        this.map({
            gatesGlow: 'gates_glow',
            cellsContainer: 'cells_container',
            drawNumber: 'draw_number'
        });
    }

    setCurrentDraw(ctx) {
        this.drawNumber.text = '№' + ctx.currentDraw;
    }

    setHistory(_, payload) {
        const draws = payload.data.draws.slice(0, 16).reverse();

        draws.forEach(draw => this.history.add({ draw: draw.draw, result: draw.number }));
    }

    setStats(_, payload) {
        this.stats.setStats(payload.data.draws);
    }

    processMessage(payload) {
        if (payload.roulette_id !== this.rouletteId)
            return;

        switch(payload.state) {
            case 'pre_forbid':
                this.service.send('FORBID', { result: payload.number });
                break;

            case 'forbid':
                this.showResultVideo(payload.number);
                this.currentDraw = payload.draw;
                this.currentResult = payload.number;
                break;
            // case 'forbid':
            //     this.service.send('FORBID', { result: payload.number });
            //     break;
            // case 'calculate':
            //     this.service.send('CALCULATE', { draw: payload.draw, result: payload.number });
            //     break;
            case 'allow':
                this.service.send('ALLOW', { draw: payload.draw });
        }
    }
}
