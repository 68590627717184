export const State = {
    NONE: 0,
    IDLE: 1,
    PRESSED: 2,
    OVER: 4,
    INACTIVE: 8,
};

export const StatePriority = [
    State.INACTIVE,
    State.OVER,
    State.PRESSED,
    State.IDLE
];

export const SkinNames = ['inactive', 'hover', 'pressed', 'idle'];

