import ContainerBuilder from './ContainerBuilder';
import ScrollBox from '../components/ScrollBox';

export default class ScrollBoxBuilder extends ContainerBuilder {
    type() {
        return 'scrollbox';
    }

    create(json, resources) {
        if (Object.hasOwnProperty.call(json, 'scrollBarDrawer')) {
            return new ScrollBox(json.options, json.scrollBarDrawer(json, resources));
        } else {
            return new ScrollBox(json.options);
        }
    }
}
