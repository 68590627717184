import { panelTitleStyle } from './TextStyles';

export default {
    type: 'sprite',
    name: 'history_container',
    texture: 'panel_background',
    x: 247,
    y: -64,
    anchor: 0.5,
    children: [
        {
            type: 'text',
            name: 'history_title',
            text: 'ИСТОРИЯ',
            scale: 0.5,
            anchor: 0.5,
            x: -43,
            y: -56,
            style: panelTitleStyle
        },
        {
            type: 'sprite',
            name: 'history_dividers',
            texture: 'history_dividers',
            anchor: 0.5,
            y: 8
        },
        {
            type: 'container',
            name: 'history_lines',
            y: -41,
            children: []
        }
    ]
}