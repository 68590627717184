import { assign } from 'core';

export default {
    id: 'game_service',
    initial: 'init',
    context: {
        rouletteData: null,
        currentDraw: 0
    },
    states: {
        init: {
            exit: [
                'setHistory',
                'setStats',
                assign({ rouletteData: (_, evt) => evt.data}),
                assign({ currentDraw: (_, evt) => evt.data.draw })
            ],
            invoke: {
                id: 'init',
                src: 'init',
                onDone: 'allow'
            }
        },
        allow: {
            entry: ['setCurrentDraw', 'allow'],
            on: {
                FORBID: { target: 'forbid' }
            }
        },
        forbid: {
            entry: ['forbid'],
            on: {
                CALCULATE: { target: 'calculate' }
            }
        },
        calculate: {
            invoke: {
                id: 'draw',
                src: 'draw'
            },
            on: {
                ALLOW: {
                    target: 'allow',
                    actions: [ assign({ currentDraw: (_, evt) => evt.draw }) ]
                }
            }
        },
    }
};
