import ButtonBuilder from './ButtonBuilder';
import Switch from '../components/Switch';

export default class SwitchBuilder extends ButtonBuilder {
    type() {
        return 'switch';
    }

    create(json, resources) {
        return new Switch();
    }

    apply(target, json, resources) {
        super.apply(target, json, resources);
        target.setActive(!!json.active);
    }
}
