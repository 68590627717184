import { timerStyle } from './TextStyles';

export default {
    type: 'container',
    name: 'timer_container',
    x: -51,
    y: -163,
    children: [
        {
            type: 'text',
            name: 'timer_text',
            text: 'ПРИЕМ СТАВОК: 00:14',
            scale: 0.5,
            anchor: 0.5,
            y: -9,
            style: timerStyle
        },
        {
            type: 'sprite',
            name: 'timer_slider_container',
            texture: 'timer_background',
            anchor: 0.5,
            children: [
                {
                    type: 'sprite',
                    name: 'timer_slider',
                    texture: 'timer_slider',
                    anchor: 0.5,
                },
                {
                    type: 'graphics',
                    name: 'timer_slider_mask',
                    draw: target => {
                        target.beginFill(0xFF0000);
                        target.drawRect(-402 / 6, -5 / 6, 402 / 3, 5 / 3);
                        target.endFill();
                    }
                }
            ]
        }
    ]
}