import SlotScene from './SlotScene';
import ViewBuilder from '../../display/ViewBuilder';
import { px_configs, px_actions, px_guards } from '../../fsm';
const { calculateFreeGames, hasFreeSpins } = px_actions;

const { getFreeSlotConfig } = px_configs;

export default class FreeSlotScene extends SlotScene {
    // constructor(view, resources, builder = new ViewBuilder()) {
    //     super(view, resources, builder);
    // }

    getConfig() {
        return getFreeSlotConfig();
    }

    getOptions() {
        const options = super.getOptions();

        options.guards.hasFreeSpins = hasFreeSpins;
        options.actions.calculate = calculateFreeGames;

        return options;
    }

    async freeSpin(context, event, meta) {
        await this.spin(context, event, meta);
    }
}
