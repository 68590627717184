import { Application } from 'core';
import GameScene from './scenes/game/GameScene';
import transport from './transport/transport';

export default class Game extends Application {
    constructor() {
        super({ width: 1920 / 3, height: 1080 / 3 });

        this.preloadAssets = [
            { name: 'resources', url: 'assets/resources.gen.@3x.json' },
            { name: 'Roboto:n1,n2,n4,n5,n7', url: 'assets/fonts/fonts.css' }
        ]
    }

    async load() {
        if (transport.closed)
            await new Promise(resolve => transport.on('connection', resolve));
    }

    generateTextures() {
        const g = new PIXI.Graphics();
        const options = { scaleMode: PIXI.SCALE_MODES.NEAREST, resolution: 3 };
        const generate = graphics => this.renderer.generateTexture(graphics, options);

        g.lineStyle(0.5, 0x358031);

        for (let i = 0; i < 8; i++) {
            g.moveTo(-402 / 6, i * 14);
            g.lineTo(402 / 6, i * 14);
        }

        g.moveTo(0, 0);
        g.lineTo(0, 8 * 14);

        this.resources['history_dividers'] = generate(g);

        g.clear();

        for (let i = 0; i < 3; i++) {
            g.beginFill(0xB27C2C);
            g.drawRect(0, i * 28, 402 / 3, 14);
            g.endFill();

            g.beginFill(0x2D6486);

            for (let j = 0; j < 4; j++)
                g.drawRect(j * 402 / 12 + 402 / 24 * (i % 2), i * 28, 402 / 24, 14);

            g.endFill();
        }

        g.beginFill(0x5b6F48);

        for (let i = 0; i < 2; i++)
            g.drawRect(i * 402 / 6, 6 * 14, 402 / 8, 14);

        g.endFill();

        g.lineStyle(0.5, 0x358031);

        for (let i = 0; i < 8; i++) {
            g.moveTo(0, i * 14);
            g.lineTo(402 / 3, i * 14);
        }

        const longDividers = [3, 4, 7];

        for (let i = 1; i < 8; i++) {
            g.moveTo(i * 402 / 24, 0);
            g.lineTo(i * 402 / 24, (longDividers.includes(i) ? 7 : 6) * 14);
        }

        this.resources['stats_dividers'] = generate(g);
    }

    async startGame(context, event) {
        return new Promise((resolve, reject) => {
            this.generateTextures();
            const gameScene = new GameScene(this.resources);

            this.addScene('game', gameScene);

            const service = gameScene.createService({ parent: this.fsm });

            const done = () => {
                this.removeScene('game');
                resolve();
            };

            service.onDone(done).start();
        });
    }
}
