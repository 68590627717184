import WebFont from 'webfontloader';

export default class WebFontLoaderPlugin {
    static use(resource, next) {
        if (resource.extension !== 'css') {
            next();
            return;
        }

        const options = {
            custom: {
                families: resource.name.split(' '),
                urls: [resource.url]
            },
            timeout: 30000,
            inactive: () => {
                resource.error = true;
                next();
            },
            active: next
        };

        WebFont.load(options);
    }
}
