import Builder from './Builder';

export default class ContainerBuilder extends Builder {
    type() {
        return 'container';
    }

    create(json, resources) {
        return new PIXI.Container();
    }

    apply(target, json, resources) {
        super.apply(target, json, resources);

        if (typeof json.interactiveChildren === 'boolean')
            target.interactiveChildren = json.interactiveChildren;

        if (typeof json.sortableChildren === 'boolean')
            target.sortableChildren = json.sortableChildren;
    }
}
