import { send } from 'xstate';

export default send((c, e) => {
    switch (e.code) {
    case 'Space':
        return 'SPIN';
    }

    return '';
});
