const initDebug = view => {
    global.debugView = view;
    global.debug = name => {
        global.debugObj = view.getChildByName(name, true);
    };

    document.addEventListener('keyup', e => {
        if (!global.debugObj)
            return;

        switch(e.code) {
            case 'KeyW':
                global.debugObj.y -= e.ctrlKey ? 10 : 1;
                break;
            case 'KeyS':
                global.debugObj.y += e.ctrlKey ? 10 : 1;
                break;
            case 'KeyA':
                global.debugObj.x -= e.ctrlKey ? 10 : 1;
                break;
            case 'KeyD':
                global.debugObj.x += e.ctrlKey ? 10 : 1;
                break;
            case 'KeyE':
                global.debugObj.scale.x += 0.01;
                global.debugObj.scale.y += 0.01;
                break;
            case 'KeyQ':
                global.debugObj.scale.x -= 0.01;
                global.debugObj.scale.y -= 0.01;
                break;
            case 'KeyZ':
                global.debugObj.rotation -= e.ctrlKey ? 0.1 : 0.01;
                break;
            case'KeyC':
                global.debugObj.rotation += e.ctrlKey ? 0.1 : 0.01;
                break;
            case 'Enter':
                console.table({ [global.debugObj.name]: { x: global.debugObj.x, y: global.debugObj.y, scale: global.debugObj.scale.x, rotation: global.debugObj.rotation }});
                break;
        }
    });
}

export default initDebug;
