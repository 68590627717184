import ContainerBuilder from './ContainerBuilder';
import Button from '../components/Button';
import { SkinNames } from '../components/ButtonEnum';

export default class ButtonBuilder extends ContainerBuilder {
    type() {
        return 'button';
    }

    create(json, resources) {
        return new Button();
    }

    apply(target, json, resources) {
        super.apply(target, json, resources);

        if (typeof json.skins === 'object') {
            const skins = {};

            for (const name of SkinNames) {
                if (typeof json.skins[name] === 'string') {
                    const skin = resources[json.skins[name]];
                    if (skin === undefined)
                        console.warn(`Cannot find resource with name: ${name}`);
                    skins[name] = skin;
                }
            }

            target.setSkins(skins);
        }
    }
}
