import { assign } from 'xstate';
import { CalculateLine } from '../configs';

export const updateMatrix = assign({ matrix: (_, { data }) => data.matrix });
export const selectLines = assign({ selected_lines: (_, { data }) => data.selected_lines });

const calculateLineForSymbol = (matrix, line, table, wildSymbols, wildMultiplier, firstSymbol, mathType) => {
    const foundLines = [];

    for (let start = 0; start < line.rows.length;) {
        let length = 0;
        let wildpresented = false;

        for (let col = start, last = line.rows.length; col < last && col < line.rows.length; ++col) {
            const row = line.rows[col];

            if (matrix[row][col] === firstSymbol) {
                ++length;
            }
            else if (wildSymbols.indexOf(parseInt(matrix[row][col])) !== -1) {
                ++length;
                wildpresented = true;
            }
            else {
                break;
            }
        }

        if (length)
            foundLines.push({ start, length, wildpresented });

        start += length + 1;
    }

    for (let i = 0, last = foundLines.length; i < last; ++i) {
        let currentLine = CalculateLine.None;
        let win = 0;
        const length = foundLines[i].length;

        win = table[firstSymbol][length] *
				    ((foundLines[i].wildpresented && wildSymbols.indexOf(parseInt(firstSymbol)) === -1) ? wildMultiplier : 1);

        if (foundLines[i].start === 0)
            currentLine = currentLine | CalculateLine.Left;

        if ((foundLines[i].start + foundLines[i].length) >= line.rows.length)
            currentLine = currentLine | CalculateLine.Right;

        if ((foundLines[i].start > 0) &&
				    ((foundLines[i].start + foundLines[i].length) < line.rows.length))
            currentLine = CalculateLine.Middle;

        if (win >= line.price && (mathType & currentLine)) {
            line.symbol = firstSymbol;
            line.win = win;
            line.matchLength = length;
            line.start = foundLines[i].start;
        }
    }
};

export const calcLines = (context, { data }) => {
    const {
        matrix,
        slot_lines,
        selected_lines,
        paytable,
        wild_multiplier,
        wild_symbols,
        symbols_without_wild,
        scatter_symbol,
    } = context;

    const combinationLengthsByLineId = {};

    for (let i = 0, last = slot_lines.length; i < last; ++i) {
        const line = slot_lines[i];

        line.price = 0;
        line.symbol = -1;
        line.start = 0;

        for (let symbol = 0, lastSymbol = paytable.length; symbol < lastSymbol; ++symbol) {
            if (symbol === scatter_symbol)
                continue;

            const useWild = (symbols_without_wild.indexOf(parseInt(symbol)) === -1);

            calculateLineForSymbol(matrix, line, paytable, (useWild ? wild_symbols : []), wild_multiplier, symbol, line.mathType);
        }

        if (combinationLengthsByLineId[i] === undefined)
            combinationLengthsByLineId[i] = 0;

        if (combinationLengthsByLineId[i] === line.rows.length)
            line.price = 0;

        combinationLengthsByLineId[i] = Math.max(combinationLengthsByLineId[i], line.matchLength);

        if (line.price > 0)
            console.info(`'Lol we win on line ${line.id}, price: ${line.price}`);
    }

    return slot_lines;
};

export const calcScatters = ({ matrix, scatter_symbol }, { data }) => {
    const scatters = [];

    matrix.forEach((row, y) => row.forEach((s, x) => {
        if (s === scatter_symbol) {
            scatters.push({ x, y });
        }
    }));

    return scatters;
};

export const calculate = assign({
    slot_lines: calcLines,
    scatters: calcScatters,
});
