import { State, StatePriority, SkinNames } from './ButtonEnum';

export default class Button extends PIXI.Container {
    constructor() {
        super();
        this.buttonMode = true;
        this._state = 1;
        this.skins = {};

        this.onPressed(() => this.state |= State.PRESSED);
        this.onUnpressed(() => this.state &= ~State.PRESSED);
        this.onUnpressedOutside(() => this.state &= ~State.PRESSED);
        this.onPointerOver(() => this.state |= State.OVER);
        this.onPointerOut(() => this.state &= ~State.OVER);
    }

    setSkins(skins) {
        this.skins = skins;
        this.stateChanged();
    }

    stateChanged() {
        this.emit('state_changed', { state: this._state });
    }

    onStateChanged(handler, context) {
        this.on('state_changed', handler, context);
    }

    onPressed(handler, context) {
        this.on('pointerdown', handler, context);
    }

    onUnpressed(handler, context = this) {
        this.on('pointerup', handler, context);
    }

    onUnpressedOutside(handler, context = this) {
        this.on('pointerupoutside', handler, context);
    }

    offClick(handler, context) {
        this.off('pointertap', handler, context);
    }

    onClick(handler, context = this) {
        this.on('pointertap', handler, context);
    }

    enableInteraction(enable = true) {
        this.interactive = enable;

        if (enable)
            this.state &= ~State.INACTIVE;
        else
            this.state |= State.INACTIVE;
    }

    onPointerOver(handler, context = this) {
        this.on('pointerover', handler, context);
    }

    onPointerOut(handler, context = this) {
        this.on('pointerout', handler, context);
    }

    appear(delay = 0) {
        return Promise.resolve();
    }

    disappear(delay = 0) {
        return Promise.resolve();
    }

    isInState(state) {
        return (this._state & state) !== 0;
    }

    get isPointerOver() {
        return this.isInState(State.OVER);
    }

    get isPressed() {
        return this.isInState(State.PRESSED);
    }

    get state() {
        return this._state;
    }

    set state(value) {
        this._state = value;
        this.stateChanged();
    }
}
