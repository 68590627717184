import Button from './Button';

export default class Switch extends Button {
    constructor() {
        super();

        this.enableInteraction();

        this._active = null;
    }

    onSwitch(handler, context = this) {
        this.on(Switch.CHANGED, handler, context);
    }

    getActive() {
        return this._active;
    }

    setActive(value) {
        if (this._active !== value)
            this.emit(Switch.CHANGED, { active: this._active = value });
    }
}

Switch.CHANGED = Symbol('Switch.CHANGED');
