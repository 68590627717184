import MultiPackLoaderPlugin from '../loader/MultiPackLoaderPlugin';
import WebFontLoaderPlugin from '../loader/WebFontLoaderPlugin';
import Viewport from './Viewport';
import MusicPlayer from '../music/MusicPlayer';
import { createMachine, createService } from '../fsm';
import TweenManager from '../tweens/TweenManager';
import config from './config/base.js';
import { Keyboard, SafeArea, isMobile } from '../utils';

const IS_PRODUCTION = true;

const defaultOptions = {
    autoDensity: true,
    clearBeforeRender: false,
    resizeTo: window,
    view: document.getElementById('view'),
    resolution: Math.min(window.devicePixelRatio, 2)
};

export default class Application extends PIXI.Application {
    constructor(options = {}) {
        const urlParams = new URLSearchParams(window.location.search);
        const res = urlParams.get('res');

        let resolution = 2;

        if (res === 'high') {
            resolution = 3;
        } else if (res === 'low') {
            resolution = 1;
        }

        super({ ...defaultOptions, ...options });

        const extensions = PIXI.compressedTextures.detectExtensions(this.renderer, Math.max(resolution, 2), 2);

        this.loader.pre(PIXI.compressedTextures.extensionChooser(extensions));

        this.preloadAssets = [];

        this.resources = {};

        MusicPlayer.setResources(this.resources);
        this.loader.use(this.unwrap.bind(this));

        this.viewport = new Viewport(options);
        this.stage.addChild(this.viewport);

        this.stage.addChild(Keyboard);
        Keyboard.on('focus', this.onKeyboardFocus, this);
        Keyboard.on('blur', this.onKeyboardBlur, this);

        this.renderer.on('resize', this.onResize, this);

        if (!IS_PRODUCTION) {
            this.fps = new PIXI.Text('', {
                fontSize: 30,
                fill: 0xFF0000,
            });
            this.stage.addChild(this.fps);
        }
    }

    getConfig() {
        return config;
    }

    getOptions() {
        return {
            actions: {
                showError: this.showError.bind(this),
            },
            services: {
                initialize: this.initialize.bind(this),
                preload: this.preload.bind(this),
                load: this.load.bind(this),
                game_service: this.startGame.bind(this),
            }
        };
    }

    run() {
        const config = this.getConfig();

        const options = this.getOptions();

        const machine = createMachine(config, options);

        this.fsm = createService(machine);

        this.fsm
            .onTransition(state => console.log(state.value))
            .start();
    }

    getScene(name) {
        return this.viewport.getScene(name);
    }

    addScene(name, scene) {
        return this.viewport.addScene(name, scene);
    }

    removeScene(name) {
        return this.viewport.removeScene(name);
    }

    onKeyboardFocus() {
        if (isMobile) {
            Keyboard.visible = true;

            const y = -Keyboard.height - SafeArea.bottomInset;

            this.stage.removeTweens();
            this.stage.addTween(new PIXI.Tween())
                .to({ y })
                .duration(300)
                .ease(PIXI.TweenEasing.Sinusoidal.Out);
        }
    }

    onKeyboardBlur() {
        if (isMobile) {
            this.stage.removeTweens();
            this.stage.addTween(new PIXI.Tween())
                .to({ y: 0 })
                .duration(300)
                .ease(PIXI.TweenEasing.Sinusoidal.In)
                .promise()
                .then(() => Keyboard.visible = false);
        }
    }

    onResize(width, height) {
        this.viewport.resize(width, height);

        Keyboard.resize(width, height);
        Keyboard.y = height;
        Keyboard.blur();
    }

    update(dt) {
        TweenManager.update(dt);
        if (!IS_PRODUCTION) {
            this.fps.text = this.ticker.FPS.toFixed(2);
        }
    }

    loadSync(resources, onProgress) {
        return new Promise((resolve, reject) => {
            this.loader.onError.detachAll();
            this.loader.onComplete.detachAll();
            this.loader.onProgress.detachAll();

            this.loader.onError.add(() => reject());
            this.loader.onComplete.add(() => resolve());
            if (onProgress)
                this.loader.onProgress.add(onProgress);

            this.loader.add(resources).load();
        });
    }

    unwrap(resource, next) {
        if (resource.type === PIXI.LoaderResource.TYPE.IMAGE) {
            this.addToResources(resource.name, resource.texture);
        } else if (resource.type === PIXI.LoaderResource.TYPE.JSON) {
            if (resource.textures) {
                for (const name in resource.textures)
                    this.addToResources(name, resource.textures[name]);
            }
            if (resource.spineData)
                this.addToResources(resource.name, resource.spineData);
        } else if (resource.extension === 'mp3' || resource.extension === 'wav') {
            this.addToResources(resource.name, resource.sound);
        } else if (resource.extension !== 'css' && resource.extension !== 'atlas') {
            console.warn('Unhandled resource', resource);
        }

        next();
    }

    addToResources(name, res) {
        if (Object.hasOwnProperty.call(this.resources, name)) {
            console.error(`Resource unique name constraint ${name}`);
            return;
        }

        this.resources[name] = res;
    }

    // actions
    showError(_, event) {
        console.error(event.data);
    }

    // services
    async initialize() {
        this.ticker.speed = 1/60;
        this.ticker.add(this.update, this);
        this.resize();

        return Promise.resolve();
    }

    async preload(context, event) {
        await this.loadSync(this.preloadAssets);
    }

    async load(context, event) {
        return Promise.resolve();
    }

    async startGame(context, event) {
        return Promise.resolve();
    }
}

PIXI.Loader.registerPlugin(MultiPackLoaderPlugin);
PIXI.Loader.registerPlugin(WebFontLoaderPlugin);
