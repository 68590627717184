import ContainerBuilder from './ContainerBuilder';

export default class SpriteBuilder extends ContainerBuilder {
    type() {
        return 'sprite';
    }

    create(json, resources) {
        if (typeof json.texture === 'string' && resources[json.texture] !== undefined)
            return new PIXI.Sprite(resources[json.texture]);
        else if (typeof json.texture === 'function')
            return new PIXI.Sprite(json.texture(resources));
        else
            return new PIXI.Sprite();
    }

    apply(target, json, resources) {
        super.apply(target, json, resources);


        if (typeof json.anchor === 'number')
            target.anchor.set(json.anchor);
        else if (typeof json.anchor === 'object')
            target.anchor.set(json.anchor.x || 0, json.anchor.y || 0);

        if (typeof json.blendMode === 'string') {
            switch (json.blendMode) {
            case 'add':
                target.blendMode = PIXI.BLEND_MODES.ADD;
                break;
            case 'multiply':
                target.blendMode = PIXI.BLEND_MODES.MULTIPLY;
                break;
            case 'screen':
                target.blendMode = PIXI.BLEND_MODES.SCREEN;
                break;
            default:
                target.blendMode = PIXI.BLEND_MODES.NORMAL;
                break;
            }
        }

        if (typeof json.tint === 'number')
            target.tint = json.tint;
    }
}
