import { assign } from 'xstate';
import keyboard from '../actions/keyboard';
import { base_context, slot_context } from '../context';

const defaultConfig = {
    id: 'game_service',
    initial: 'init',
    context: {
        ...base_context,
        ...slot_context,
    },
    on: {
        keydown: {
            actions: keyboard,
        },
    },
    states: {
        init: {
            invoke: {
                src: 'init',
                onDone: [
                    // TODO: assing data
                    // TODO: restore
                    { target: 'game', actions: [assign((_, { data }) => data)] },
                ],
            },
        },
        game: {
            initial: 'welcome',
            states: {
                welcome: {
                    invoke: {
                        src: 'welcome',
                        onDone: {
                            target: 'idle',
                            actions: ['setMatrix'],
                        },
                    },
                },
                idle: {
                    on: {
                        SPIN: {
                            target: 'request_spin', cond: 'canRequestSpin',
                        },
                        MAX_BET: {},
                        MIN_BET: {},
                        SELECT_LINES: { actions: ['selectLines'] },
                    }
                },
                request_spin: {
                    invoke: {
                        src: 'requestSpin',
                        onDone: {
                            target: 'spinning',
                            actions: ['updateBalance', 'updateMatrix', 'calculate'],
                        },
                    },
                },
                spinning: {
                    invoke: {
                        src: 'spin',
                        onDone: 'show_win_anim',
                    },
                    on: {
                        SPIN: { actions: ['stopSpin'] },
                    }
                },
                show_win_anim: {
                    invoke: {
                        src: 'showWinAnim',
                        onDone: [
                            { target: 'take_win', cond: 'spinWon' },
                            { target: 'idle' },
                        ],
                    }
                },
                take_win: {
                    on: {
                        SPIN: 'request_take_win',
                    }
                },
                request_take_win: {
                    invoke: {
                        src: 'requestTakeWin',
                        onDone: {
                            target: 'show_take_win_anim',
                            actions: assign({ balance: (_, { data }) => data.balance }),
                        },
                    }
                },
                show_take_win_anim: {
                    invoke: {
                        src: 'showTakeWinAnim',
                        onDone: 'idle',
                    }
                },
            },
            onDone: 'exit',
        },
        exit: { type: 'final' },
    }
};

export default (...options) => {
    const config = defaultConfig;

    options.forEach(fn => fn(options));

    return config;
};
