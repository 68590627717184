export default class Timer {
    constructor(view) {
        this.timerText = view.getChildByName('timer_text', true);
        this.timerMask = view.getChildByName('timer_slider_mask', true);

        view.getChildByName('timer_slider', true).mask = this.timerMask;

        this.maskPositions = {
            start: -134,
            end: 0
        };
    }

    start(time, prefix = '') {
        this.timerMask.removeTweens();
        this.timerMask.x = this.maskPositions.start;

        clearInterval(this.updateInterval);

        this.timerMask.addTween().to({ x: this.maskPositions.end }).duration(time);

        this.timerText.text = prefix + this._getTimeString(time);

        const promise = new Promise(resolve => {
            this.updateInterval = setInterval(() => {
                time -= 1000;

                if (time <= 0) {
                    this.timerText.text = prefix + '00:00';
                    clearInterval(this.updateInterval);
                    resolve();
                    return;
                }

                this.timerText.text = prefix + this._getTimeString(time);
            }, 1000);
        });

        return promise;
    }

    _getTimeString(milliseconds) {
        const date = new Date(milliseconds);
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${minutes}:${seconds}`;
    }
}