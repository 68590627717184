import SpriteBuilder from './SpriteBuilder';
import Localizer from '../../utils/Localizer';

export default class TextBuilder extends SpriteBuilder {
    type() {
        return 'text';
    }

    create(json, resources) {
        let text = '';
        if (typeof json.text === 'string') {
            text = Localizer.tr(json.text);
        } else if (Array.isArray(json.text)) {
            text = Localizer.tr(...json.text);
        }

        return new PIXI.Text(text, json.style);
    }
}
