import isMobile from '../utils/isMobile';
import SafeArea from '../utils/SafeArea';

export default class Viewport extends PIXI.Container {
    constructor(options = {}) {
        super();

        this.worldWidth = options.width;
        this.worldHeight = options.height;

        this.screen = {
            rect: new PIXI.Rectangle(0, 0, this.worldWidth, this.worldHeight),
            safeRect: new PIXI.Rectangle(0, 0, this.worldWidth, this.worldHeight),
            portrait: this.worldWidth < this.worldHeight,
        };

        this.scenes = {};
    }

    resize(width, height) {
        if (this.worldWidth / this.worldHeight >= width / height) {
            this.scale.set(height / this.worldHeight);
        } else {
            this.scale.set(width / this.worldWidth);
        }

        this.position.set(width / 2, height / 2);

        const l = SafeArea.leftInset;
        const t = SafeArea.topInset;
        const r = SafeArea.rightInset;
        const b = SafeArea.bottomInset;

        this.screen.rect = this.translate(new PIXI.Rectangle(0, 0, width, height));
        this.screen.safeRect = this.translate(new PIXI.Rectangle(l, t, width - l - r, height - t - b));
        this.screen.portrait = width < height;

        this.children.forEach(child => child.resize(this.screen));
    }

    translate(rect) {
        const { x, y } = this.scale;

        rect.x = (rect.x - this.x) / x;
        rect.y = (rect.y - this.y) / y;
        rect.width /= x;
        rect.height /= y;

        return rect;
    }

    getScene(name) {
        const scene = this.scenes[name];
        if (!scene) {
            console.warn(`Cannot find scene with name ${name}`);
            return null;
        }

        return scene;
    }

    addScene(name, scene) {
        if (this.scenes[name]) {
            console.warn(`Scene with name ${name} already added`);
            return;
        }

        this.scenes[name] = scene;

        scene.resize(this.screen);

        this.addChild(scene);
    }

    removeScene(name) {
        const scene = this.scenes[name];
        if (!scene) {
            console.warn(`Cannot find scene with name ${name}`);
            return;
        }

        this.removeChild(scene);
    }
}
