export default class Stats {
    constructor(view) {
        this.textEls = view.getChildByName('stats_values_container', true);
    }

    setStats(data) {
        this.data = data.map(el => el.number).reverse();
        console.log(this.data);

        // this.values = this.data.reduce((obj, draw) => {
        //     if (!obj.hasOwnProperty(draw))
        //         obj[draw] = 1;
        //     else
        //         obj[draw]++;

        //     return obj;
        // }, {});

        this.data.forEach(number => {
            const stat = this.textEls.getChildByName(`stat_value_${number}`);

            stat.text = parseInt(stat.text) + 1;
        })

        for (const number in this.values)
            this.textEls.getChildByName(`stat_value_${number}`).text = this.values[number];
    }

    update(number) {
        const stat = this.textEls.getChildByName(`stat_value_${number}`);

        if (stat)
            stat.text = parseInt(stat.text) + 1;

        this.data.push(number);

        const removedStatNumber = this.data.shift();
        const removedStat = this.textEls.getChildByName(`stat_value_${removedStatNumber}`);

        if (removedStat)
            removedStat.text = parseInt(removedStat.text) - 1;
    }
}