import { assign } from 'xstate';

export const degree = number => {
    let d = 0;
    while (number > 0) {
        number = Math.floor(number / 10);
        d++;
    }
    return d - 1;
};

export const firstDigit = (number, d) => (number / Math.pow(10, d)) % 10 | 0;

export const increase = bet => {
    const d = degree(bet);
    let n = firstDigit(bet, d);

    if (n < 2) {
        n = 2;
    } else if (n < 5) {
        n = 5;
    } else {
        n = 10;
    }

    return n * Math.pow(10, d);
};

export const decrease = bet => {
    const d = degree(bet);
    let n = firstDigit(bet, d);

    if (n > 5) {
        n = 5;
    } else if (n > 2) {
        n = 2;
    } else if (n > 1) {
        n = 1;
    } else {
        n = 0.5;
    }

    return n * Math.pow(10, d);
};

export const incBet = assign({
    bet: ({ bet, limits }) => Math.min(limits.max_bet, increase(bet)),
});

export const decBet = assign({
    bet: ({ bet, limits }) => Math.max(limits.min_bet, decrease(bet)),
});

export const incBy = assign({
    bet: ({ bet, limits, balance, nominals }, { index }) => {
        return Math.min(
            balance,
            Math.min(limits.max_bet, bet + nominals[index])
        );
    },
});

export const halfBet = assign({
    bet: ({ bet, limits }) => Math.max(bet / 2, limits.min_bet),
});

export const doubleBet = assign({
    bet: ({ bet, balance, limits }) =>
        Math.min(Math.min(bet * 2, balance), limits.max_bet),
});

export const setBet = assign({
    bet: ({ limits, balance }, { bet }) =>
        Math.min(
            Math.min(Math.max(bet, limits.min_bet), limits.max_bet),
            balance
        ),
});

export const updateBalance = assign({ balance: (_, { data }) => data.balance });
