import { Keyboard, Key } from '../../utils';

export default class Input extends PIXI.Container {
    constructor(style, debug) {
        super();

        const width = style.boxWidth || 500;
        const height = style.boxHeight || 100;

        this._maxLength = style.maxLength || 20;

        this._back = new PIXI.Graphics();
        this._back
            .beginFill(0xff0000, debug ? 0.5 : 0)
            .drawRect(0, 0, 100, 100)
            .endFill();
        this.addChild(this._back);

        this._prefix = style.prefix || '';
        this._postfix = style.postfix || '';
        this._value = '';
        this._text = new PIXI.Text('', Object.assign({}, style));

        this._placeholder = new PIXI.Text(
            style.placeholder || '',
            Object.assign({}, style)
        );

        style.cursorFill = style.cursorFill || style.fill || '#FFFFFF';
        this._cursor = new PIXI.Graphics();
        this._cursor
            .beginFill(parseInt(style.cursorFill.replace(/^#/, ''), 16))
            .drawRect(0, -style.fontSize / 2, 1, style.fontSize)
            .endFill();

        this._cursor
            .addTween(new PIXI.Tween())
            .to({ alpha: 0 })
            .duration(1000)
            .ease(k => (k > 0.5 ? 0 : 1))
            .loop()
            .start();
        this._cursor.visible = false;

        this._cursorPos = 0;

        if (style.align === 'center')
            this._text.anchor.x = this._placeholder.anchor.x = 0.5;
        else if (style.align === 'right')
            this._text.anchor.x = this._placeholder.anchor.x = 1;

        this._text.anchor.y = this._placeholder.anchor.y = 0.5;
        this._text.y = this._placeholder.y = height / 2;
        this._text.addChild(this._cursor);
        this.addChild(this._text, this._placeholder);

        this._focused = false;
        this.interactive = true;
        this.buttonMode = true;
        this.hitArea = new PIXI.Rectangle(0, 0, 1, 1);
        this.width = width;
        this.height = height;
        this.on('pointertap', this.focus, this);
    }

    enableInteraction(enable = true) {
        this.interactive = enable;
    }

    focus() {
        Keyboard.setInput(this);

        this._focused = true;
        this._cursorPos = this._text.text.length;
        this.text = this.text; // update placeholder

        Keyboard.off('keydown');
        Keyboard.on('keydown', this._onKeyDown, this);
        // Keyboard.once('blur', this.blur, this);
        Keyboard.focus();

        this.emit('focus');
    }

    blur() {
        if (!this._focused) return;

        this._focused = false;

        Keyboard.off('keydown', this._onKeyDown, this);
        // Keyboard.blur();

        if (this._text.text.length > 0) {
            const value = parseFloat(this._text.text);
            if (value <= 0) this._setText('');
            else this._setText(value.toFixed(2));
        } else {
            this._setText('');
        }

        this.emit('blur', this._text.text);
    }

    _onKeyDown(key) {
        if (key === Key.DEL) {
            if (this._text.text.length > 0) {
                this.text = this._text.text.substring(
                    0,
                    this._text.text.length - 1
                );
                this._cursorPos--;
            }
            return;
        }

        if (key === Key.DOT) {
            // empty input field
            if (this._text.text.length === 0) return;

            // if text already has '.'
            if (this._text.text.indexOf(Key.DOT) > 0) return;
        }

        // do now allow input invalid number
        if (this.text === '0' && key !== Key.DOT) {
            return;
        }

        const pos = this._cursorPos++;
        this.text = [
            this._text.text.slice(0, pos),
            key,
            this._text.text.slice(pos),
        ].join('');
    }

    _updateCursor() {
        this._cursor.visible = this._focused;

        if (this._focused) {
            if (this._text.style.align === 'center') {
                this._cursor.x = this._text.width / this._text.scale.x / 2;
            } else if (this._text.style.align === 'right') {
                this._cursor.x = this._text.width;
            } else {
                this._cursor.x = this._text.width;
            }
        }
    }

    _setText(text) {
        text = text.toString();
        if (text.length <= this._maxLength) {
            this._text.text = text;
            this._text.visible = this._focused
                ? this._focused
                : this._text.text.length > 0;
            this._placeholder.visible = !this._text.visible;

            this._text.scale.set(
                Math.min(
                    this.width / (this._text.width / this._text.scale.x),
                    1
                )
            );

            this._updateCursor();
        }
    }

    get text() {
        return this._text.text;
    }

    set text(text) {
        text = text.toString();
        if (text.length <= this._maxLength) {
            this._setText(text);
            this.emit('input', text);
        }
    }

    get placeholder() {
        return this._placeholder.text;
    }

    set placeholder(value) {
        this._placeholder.text = value;
    }

    get height() {
        return this.hitArea.height;
    }

    set height(height) {
        this._back.height = height;
        this._placeholder.y = this._text.y = height / 2;
        this.hitArea.height = height;
    }

    get width() {
        return this.hitArea.width;
    }

    set width(width) {
        this._back.width = width;
        if (this._text.style.align === 'center')
            this._text.x = this._placeholder.x = width / 2;
        else if (this._text.style.align === 'right')
            this._text.x = this._placeholder.x = width;
        else this._text.x = this._placeholder.x = 0;

        this.hitArea.width = width;
    }
}
