class SafeArea {
    constructor() {
        this._topInset = null;
        this._rightInset = null;
        this._bottomInset = null;
        this._leftInset = null;
    }

    setInsets(insets) {
        const orientation = this.orientation;
        const { top, right, bottom, left } = insets;

        switch (this.orientation) {
        case 'landscape-primary':
            this._topInset = left;
            this._rightInset = top;
            this._bottomInset = right;
            this._leftInset = bottom;
            break;
        case 'landscape-secondary':
            this._topInset = right;
            this._rightInset = bottom;
            this._bottomInset = left;
            this._leftInset = top;
            break;
        default:
            this._topInset = top;
            this._rightInset = right;
            this._bottomInset = bottom;
            this._leftInset = left;
        }
    }

    get orientation() {
        let orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;
        if (!orientation)
            orientation = Math.abs(window.orientation) === 90 ? 'landscape-primary' : 'portrait';
        return orientation;
    }

    get topInset() {
        if (this._topInset === null) {
            return parseInt(getComputedStyle(document.body).getPropertyValue("--sat"));
        }

        switch(this.orientation) {
        case 'landscape-primary':
            return this._rightInset / window.devicePixelRatio;
        case 'landscape-secondary':
            return this._leftInset / window.devicePixelRatio;
        }

        return this._topInset / window.devicePixelRatio;
    }

    get rightInset() {
        if (this._rightInset === null) {
            return parseInt(getComputedStyle(document.body).getPropertyValue("--sar"));
        }

        switch(this.orientation) {
        case 'landscape-primary':
            return this._bottomInset / window.devicePixelRatio;
        case 'landscape-secondary':
            return this._topInset / window.devicePixelRatio;
        }

        return this._rightInset / window.devicePixelRatio;
    }

    get bottomInset() {
        if (this._bottomInset === null) {
            return parseInt(getComputedStyle(document.body).getPropertyValue("--sab"));
        }

        switch(this.orientation) {
        case 'landscape-primary':
            return this._leftInset / window.devicePixelRatio;
        case 'landscape-secondary':
            return this._rightInset / window.devicePixelRatio;
        }

        return this._bottomInset / window.devicePixelRatio;
    }

    get leftInset() {
        if (this._rightInset === null) {
            return parseInt(getComputedStyle(document.body).getPropertyValue("--sal"));
        }

        switch(this.orientation) {
        case 'landscape-primary':
            return this._topInset / window.devicePixelRatio;
        case 'landscape-secondary':
            return this._bottomInset / window.devicePixelRatio;
        }

        return this._leftInset / window.devicePixelRatio;
    }
}

export default new SafeArea();
