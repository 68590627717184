import SpriteBuilder from './SpriteBuilder';

export default class TilingSpriteBuilder extends SpriteBuilder {
    type() {
        return 'tiling_sprite';
    }

    create(json, resources) {
        if (typeof json.texture === 'string' && resources[json.texture] !== undefined)
            return new PIXI.TilingSprite(resources[json.texture], json.width, json.height);
        else if (typeof json.texture === 'function')
            return new PIXI.TilingSprite(json.texture(resources), json.width, json.height);
        else
            return new PIXI.TilingSprite();
    }
}
