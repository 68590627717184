export default class Sound {
    constructor(sound, options = {}) {
        this._sound = sound;
    }

    get isPlaying() {
        return this._sound.isPlaying;
    }

    get loop() {
        return this._sound.loop;
    }

    set loop(loop) {
        this._sound.loop = loop;
    }

    get muted() {
        return this._sound.muted;
    }

    set muted(muted) {
        this._sound.muted = muted;
    }

    get paused() {
        return this._sound.paused;
    }

    set paused(paused) {
        this._sound.paused = paused;
    }

    get progress() {
        return this._sound.progress;
    }

    get speed() {
        return this._sound.speed;
    }

    set speed(speed) {
        this._sound.muted = speed;
    }

    get volume() {
        return this._sound.volume;
    }

    set volume(volume) {
        this._sound.volume = volume;
    }

    play(options = {}) {
        const instance = this._sound.play({
            ...this._sound.options,
            ...options,
        });

        return new Promise(resolve => {
            instance.once('end', () => resolve());
            instance.once('stop', () => resolve());
        });
    }

    stop() {
        this._sound.stop();
    }

    pause() {
        // this._instance.paused = true;
        this._sound.pause();
    }

    resume() {
        // this._instance.paused = false;
        this._sound.resume();
    }
}
