export default {
    "protocol": "https",
    "port" : 443,
    "host" : null,
    "prefix" : "transport/1",
    "is_reconnect" : true,
    "reconnect_time" : 1500,
    "request_timeout" : 5000,
    "is_check_ping_monitoring" : true,
    "check_ping_monitoring_time" : 180,
    "check_ping_monitoring_interval" : 18,
    "check_ping_monitoring_count_tick" : 10,
  };