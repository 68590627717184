import ContainerBuilder from './ContainerBuilder';
import Input from '../components/Input';
import Localizer from '../../utils/Localizer';

export default class InputBuilder extends ContainerBuilder {
    type() {
        return 'input';
    }

    create(json, resources) {
        if (typeof json.style.placeholder === 'string') {
            json.style.placeholder = Localizer.tr(json.style.placeholder);
        } else if (Array.isArray(json.style.placeholder)) {
            json.style.placeholder = Localizer.tr(...json.style.placeholder);
        }
        return new Input(json.style, json.debug);
    }
}
