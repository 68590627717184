import { assign } from 'xstate';
import { calcLines, calcScatters } from './slot_actions';

export const hasFreeSpins = ({ free_games_left }) => {
    return free_games_left > 0;
};

export const calcFreeGames = (context, event) => {
    const scattersCount = calcScatters(context, event).length;

    const { free_games_left, scatter_threshold, free_spins_gain } = context;

    if (scattersCount >= scatter_threshold) {
        return free_games_left + free_spins_gain;
    }

    return free_games_left;
};

export const calculateFreeGames = assign({
    slot_lines: calcLines,
    scatters: calcScatters,
    free_games_left: calcFreeGames,
});
