import ContainerBuilder from './ContainerBuilder';

export default class GraphicsBuilder extends ContainerBuilder {
    type() {
        return 'graphics';
    }

    create() {
        return new PIXI.Graphics();
    }

    apply(target, json) {
        super.apply(target, json);

        if (typeof json.draw === 'function') {
            json.draw(target);
        }
    }
}
