export default class Builder {
    type() {
        throw new Error('Builder type has not been specified');
    }

    create(json, resources) {
        return null;
    }

    apply(target, json, resources) {
        target.name = json.name || '';
        target.position.set(json.x || 0, json.y || 0);

        if (typeof json.scale === 'number') {
            target.scale.set(json.scale);
        } else if (typeof json.scale === 'object') {
            target.scale.set(json.scale.x || 1, json.scale.y || 1);
        }

        if (typeof json.width === 'number')
            target.width = json.width;
        if (typeof json.height === 'number')
            target.height = json.height;

        if (typeof json.alpha === 'number')
            target.alpha = json.alpha;

        if (typeof json.angle === 'number')
            target.angle = json.angle;

        if (typeof json.rotation === 'number')
            target.rotation = json.rotation;

        if (typeof json.buttonMode === 'boolean')
            target.buttonMode = json.buttonMode;

        if (typeof json.hitArea === 'object') {
            const { x = 0, y = 0, width = 0, height = 0 } = json.hitArea;
            target.hitArea = new PIXI.Rectangle(x, y, width, height);
        }

        if (typeof json.hitArea === 'function')
            target.hitArea = json.hitArea();

        if (typeof json.interactive === 'boolean')
            target.interactive = json.interactive;

        if (typeof json.pivot === 'object')
            target.pivot.set(json.pivot.x || 0, json.pivot.y || 0);

        if (typeof json.renderable === 'boolean')
            target.renderable = json.renderable;

        if (typeof json.visible === 'boolean')
            target.visible = json.visible;

        if (typeof json.skew === 'object')
            target.skew.set(json.skew.x || 0, json.skew.y || 0);

        if (typeof json.sortableChildren === 'boolean')
            target.sortableChildren = json.sortableChildren;

        if (typeof json.zIndex === 'number')
            target.zIndex = json.zIndex;

        if (typeof json.init === 'function') {
            json.init(target, json, resources);
        }

        return target;
    }

    build(json, resources) {
        const target = this.create(json, resources);

        this.apply(target, json, resources);

        return target;
    }
}
