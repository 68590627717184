import EE from 'eventemitter3';
import TweenEasing from './TweenEasing';
import TweenManager from './TweenManager';

export default class Tween extends EE {
    constructor() {
        super();

        this._playing = false;
        this._time = 0;
        this._from = {};
        this._to = {};
        this._duration = 0;
        this._delay = 0;
        this._yoyo = false;
        this._loop = 1;
        this._startLoop = 1;
        this._detach = false;
        this._ease = TweenEasing.Linear;
        this._target = null;
        this._autoStart = true;
        this._resolve = null;
        this._reject = null;
        this._promise = null;
    }

    from(from) {
        this._from = from;
        return this;
    }

    to(to) {
        this._to = to;

        return this;
    }

    fps(duration) {
        this._duration = duration * 1000 / 30;
        return this;
    }

    duration(duration) {
        this._duration = duration;
        return this;
    }

    delay(delay) {
        this._delay = delay;
        return this;
    }

    yoyo(yoyo = true) {
        this._yoyo = yoyo;
        return this;
    }

    loop(loop = -1) {
        this._loop = this._startLoop = loop;
        return this;
    }

    detach(detach = true) {
        this._detach = detach;
        return this;
    }

    onComplete(onComplete) {
        this.on('complete', onComplete);
        return this;
    }

    ease(ease) {
        this._ease = ease;
        return this;
    }

    autoStart(autoStart = true) {
        this._autoStart = autoStart;
        return this;
    }

    setTarget(target) {
        this._target = target;
        return this;
    }

    promise() {
        if (this._promise === null) {
            this._promise = new Promise((resolve, reject) => {
                this._resolve = resolve;
                this._reject = reject;
            }).catch(() => null);
        }

        return this._promise;
    }

    start() {
        this._playing = true;

        if (Object.keys(this._from).length === 0) {
            Tween.getProps(this._target, this._to, this._from);
        }

        this.emit('started');
        return this;
    }

    stop() {
        if (!this._playing)
            return this;

        this._playing = false;
        this.emit('stopped');
        return this;
    }

    complete(complete = true) {
        if (!this._playing)
            return;

        if (this._detach && this._target && this._target.parent)
            this._target.parent.removeChild(this._target);

        this.stop();

        if (this._promise !== null) {
            if (complete)
                this._resolve();
            else
                this._reject();

            this._promise = null;
            this._resolve = null;
            this._reject = null;
        }

        if (complete)
            this.emit('complete', this);

        TweenManager.removeTween(this);
    }

    clear() {
        this._playing = false;
        this._time = 0;
        this._from = {};
        this._to = {};
        this._duration = 0;
        this._delay = 0;
        this._yoyo = false;
        this._loop = 1;
        this._detach = false;
        this._ease = TweenEasing.Linear;
        this._target = null;
        this._useFPS = false;
    }

    reset(resetLoop = false) {
        this._time = 0;

        if (resetLoop)
            this._loop = this._startLoop;

        this._apply(0);
    }

    _apply(elapsed) {
        if (!this._target)
            return;

        Tween.apply(this._from, this._to, this._target, elapsed);
    }

    calcEase(elapsed) {
        elapsed = elapsed > 1 ? 1 : elapsed;

        if (this._yoyo) {
            if (elapsed > 0.5)
                elapsed = 1 - elapsed;
            elapsed *= 2;
        }

        return this._ease(elapsed);
    }

    isPlaying() {
        return this._playing;
    }

    update(dt) {
        if (!this._playing) {
            if (this._autoStart)
                this.start();
            else
                return;
        }

        this._time += dt*1000;

        if (this._time < this._delay)
            return;

        let elapsed = (this._time - this._delay) / this._duration;

        this._apply(this.calcEase(elapsed));

        if (elapsed >= 1)
        {
            this._loop--;

            if (!this._loop) {
                this.complete();
            } else {
                this.reset();
            }
        }
    }

    static apply(from, to, target, elapsed = 0) {
        for (const prop in to) {
            if (from[prop] === undefined || target[prop] === undefined)
                continue;

            if (typeof to[prop] === 'object') {
                Tween.apply(from[prop], to[prop], target[prop], elapsed);
            } else {
                const start = from[prop];
                const end   = to[prop];

                target[prop] = start+(end-start)*elapsed;
            }
        }
    }

    static getProps(target, props, to) {
        for (const prop in props) {
            if (target[prop] === undefined)
                continue;

            if (typeof props[prop] === 'object') {
                to[prop] = {};
                Tween.getProps(target[prop], props[prop], to[prop]);
            } else {
                to[prop] = target[prop];
            }
        }
    }
}
