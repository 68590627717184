import Scene from '../Scene';
import ViewBuilder from '../../display/ViewBuilder';
import { rotate } from '../../utils/math';
import ReelsGenerator from './ReelsGenerator';
import MusicPlayer from '../../music/MusicPlayer';
import { px_configs, px_actions, px_guards } from '../../fsm';

const { getSlotConfig } = px_configs;
const { enoughBalance, spinWon } = px_guards;
const { updateBalance, updateMatrix, selectLines, calculate } = px_actions;

const noop = async () => {};

export default class SlotScene extends Scene {
    constructor(view, resources, builder = new ViewBuilder()) {
        super(view, resources, builder);

        this.reelsGenerator = new ReelsGenerator(10);

        this.slotColumns = new Array(5).fill(0).
            map((_, i) => this.view.getChildByName(`slot_column_${i}`));

        this.enableKeyboard();
    }

    getConfig() {
        return getSlotConfig();
    }

    getOptions() {
        return {
            guards: {
                canRequestSpin: enoughBalance,
                spinWon,
            },
            actions: {
                updateBalance,
                updateMatrix,
                calculate,
                selectLines,
                setMatrix: this.setMatrix.bind(this),
                stopSpin: this.stopSpin.bind(this),
            },
            services: {
                welcome: noop,
                init: this.init.bind(this),
                requestSpin: this.requestSpin.bind(this),
                requestTakeWin: this.requestTakeWin.bind(this),
                spin: this.spin.bind(this),
                showWinAnim: this.showWinAnim.bind(this),
                showTakeWinAnim: this.showTakeWinAnim.bind(this),
            }
        };
    }

    async init() {
        console.log('init');
        return {
            matrix: [[0,1,2,3,4],[0,1,2,3,4],[0,1,2,3,4]],
            balance: 10000000,
            bet: 100,
        };
    }

    async requestSpin({ matrix, balance, bet }, event) {
        const prevMatrix = matrix;

        matrix = new Array(3).fill(0).map(() => new Array(5).fill(0).map(() => Math.floor(Math.random() * 10)));

        matrix[0][0]=9;
        matrix[0][1]=9;
        matrix[0][2]=9;
        console.table(matrix);
        return {
            balance: balance - bet,
            matrix,
            prevMatrix,
        };
    }

    async requestTakeWin({ slot_lines, balance }, event) {
        return {
            balance: balance + slot_lines.reduce((acc, l) => acc += l.win, 0),
        };
    }

    stopSpin(context, event) {
        const tweens = this.slotColumns.map(c => c.spinTween);

        let delay = 0;
        tweens.forEach(t => {
            if (t.isPlaying()) {
                delay += 33;
                t._time = (t._duration + t._delay) - delay;
            }
        });
    }

    async spin({ matrix, scatter_symbol, fake_symbols }, event) {
        const { prevMatrix } = event.data;
        const reels = this.reelsGenerator.generate(prevMatrix, fake_symbols, matrix, scatter_symbol);

        const timePerSymbol = 83;

        const spinSound = MusicPlayer.getSound('novomatic_deluxe_spin');

        spinSound.play({ loop: true });

        this.spinTweens = this.slotColumns.map((column, i) => {
            const spinDur = (reels[i].tape.length - 4) * timePerSymbol;
            return column.spin(reels[i].tape, spinDur, 'novomatic_deluxe_reels_stop');
        });

        await Promise.all(this.spinTweens.map(t => t.promise()));

        spinSound.stop();
    }

    async showWinAnim(context, event) {
        console.table(context.slot_lines);
    }

    async showTakeWinAnim(context, event) {

    }

    setMatrix({ matrix }, event) {
        const columns = rotate(matrix);

        this.slotColumns.forEach((column, i) => column.setColumn(columns[i]));
    }

    updateSelectedLines(context, event) {
        console.log('updateSelectedLines');
    }

    enableKeyboard() {
        window.addEventListener('keydown', e => {
            this.service.send(e);
        });
    }
}
