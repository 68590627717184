export default class Key extends PIXI.Container {
    constructor(code) {
        super();

        this._code = code;
        this._front = new PIXI.Graphics();
        this._front.beginFill(0xFFFFFF).drawRoundedRect(0, 0, 100, 50, 30).endFill();
        this._front.tint = 0x3D3D3D;

        this._shadow = new PIXI.Graphics();
        this._shadow.beginFill(0x000000).drawRoundedRect(0, 102, 100, 50, 30).endFill();

        this._front.interactive = true;
        this._front.buttonMode = true;
        this._front.on('pointerdown', this._onPressed, this);
        this._front.on('pointerup', this._onUnpressed, this);
        this._front.on('pointerupoutside', this._onUnpressed, this);
        this._front.on('pointertap', this._onTap, this);

        const style = new PIXI.TextStyle({
            fontFamily: 'Arial',
            fontSize: 80,
            fill: '#FFFFFF',
        });
        this._text = new PIXI.Text(code, style);
        this._text.anchor.set(0.5);
        this._text.position.set(168, 84);
        this._front.addChild(this._text);

        this.addChild(this._shadow, this._front);
    }

    _onPressed() {
        this._front.tint = 0x303030;
    }

    _onUnpressed() {
        this._front.tint = 0x3D3D3D;
    }

    _onTap() {
        this.emit('keydown', this._code);
    }

    resize(width, height, radius, fontSize) {
        this._front.clear().beginFill(0xFFFFFF).drawRoundedRect(0, 0, width, height, radius).endFill();
        this._shadow.clear().beginFill(0x000000).drawRoundedRect(0, radius / 8, width, height, radius).endFill();
        this._text.style.fontSize = fontSize;
        this._text.position.set(width / 2, height / 2);
    }
}

Key.CODE_1 = '1';
Key.CODE_2 = '2';
Key.CODE_3 = '3';
Key.CODE_4 = '4';
Key.CODE_5 = '5';
Key.CODE_6 = '6';
Key.CODE_7 = '7';
Key.CODE_8 = '8';
Key.CODE_9 = '9';
Key.DOT    = '.';
Key.CODE_0 = '0';
Key.DEL    = '<';
Key.CLOSE  = 'X';
