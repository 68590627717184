import ContainerBuilder from './ContainerBuilder';
import SlotColumn from '../components/SlotColumn';

// const getTextures = (anims, resources) => {
//     return anims.map({ texture, frames } => {
//         for (let i = 0; i < frames.length; ++i) {
//             const name = `${texture}`
//         }
//         if (Object.hasOwnProperty.call(resources, texture)) {
//             return 
//         }
//     });
// };

const getAnims = (anims, resources) => {
    return anims.map(frames => frames.map(frame => resources[frame]));
};

export default class SlotColumnBuilder extends ContainerBuilder {
    type() {
        return 'slot_column';
    }

    create(json, resources) {
        return new SlotColumn();
    }

    apply(target, json, resources) {
        super.apply(target, json, resources);

        if (typeof json.mask_width === 'number' && typeof json.mask_height === 'number') {
            target.clip(json.mask_width, json.mask_height);
        }

        if (json.anims instanceof Array) {
            target.setAnims(getAnims(json.anims, resources));
        }
    }
}


