import { panelDataStyle, panelTitleStyle, statsLabelsStyle } from './TextStyles';

const labels = new Array(24).fill().map((_, i) => ({
    type: 'text',
    name: 'stat_label_' + i,
    text: (i + 1).toString(),
    scale: 0.5,
    anchor: 0.5,
    x: i % 8 * 402 / 24,
    y: Math.floor(i / 8) * 28,
    style: statsLabelsStyle
}));

labels.push({
    type: 'text',
    name: 'stat_label_24',
    text: 'П/О',
    scale: 0.5,
    anchor: 0.5,
    x: 402 / 24,
    y: 6 * 14,
    style: statsLabelsStyle
});

labels.push({
    type: 'text',
    name: 'stats_label_25',
    text: 'Штанга',
    scale: 0.5,
    anchor: 0.5,
    x: 5 * 402 / 24,
    y: 6 * 14,
    style: statsLabelsStyle
});

const values = new Array(24).fill().map((_, i) => ({
    type: 'text',
    name: 'stat_value_' + (i + 1),
    text: '0',
    scale: 0.5,
    anchor: 0.5,
    x: i % 8 * 402 / 24,
    y: Math.floor(i / 8) * 28,
    style: panelDataStyle
}));

values.push({
    type: 'text',
    name: 'stat_value_25',
    text: '0',
    scale: 0.5,
    anchor: 0.5,
    x: 402 / 24 * 3,
    y: 5 * 14,
    style: panelDataStyle
});

values.push({
    type: 'text',
    name: 'stat_value_0',
    text: '0',
    scale: 0.5,
    anchor: 0.5,
    x: 402 / 24 * 7,
    y: 5 * 14,
    style: panelDataStyle
});

export default {
    type: 'sprite',
    name: 'stats_container',
    texture: 'panel_background',
    anchor: 0.5,
    x: 247,
    y: 68,
    children: [
        {
            type: 'text',
            name: 'stats_title',
            text: 'СТАТИСТИКА',
            scale: 0.5,
            anchor: 0.5,
            x: -36,
            y: -56,
            style: panelTitleStyle
        },
        {
            type: 'sprite',
            name: 'stats_dividers',
            texture: 'stats_dividers',
            x: -67,
            y: -47
        },
        {
            type: 'container',
            name: 'stats_labels_container',
            x: -58,
            y: -40,
            children: labels
        },
        {
            type: 'container',
            name: 'stats_values_container',
            x: -58,
            y: -26,
            children: values
        },
        {
            type: 'text',
            name: 'stats_info',
            text: 'Последние 200 пенальти',
            scale: 0.5,
            anchor: 0.5,
            y: 57,
            style: { ...statsLabelsStyle, fontSize: 14 }
        }
    ]
}