import { cellNumberStyle } from '../view/TextStyles';

export default class Cell extends PIXI.Sprite {
    constructor(res, number) {
        const index = number - 1
        const color = (index + Math.floor(index / 8)) % 2 === 0 ? 'blue' : 'orange';

        super(res['cell_' + color])

        this.anchor.set(0.5);
        this.name = 'cell_' + number;

        this.number = new PIXI.Text(number, cellNumberStyle);
        this.number.anchor.set(0.5);
        this.number.scale.set(0.5);

        this.glow = new PIXI.Sprite(res['cell_win']);
        this.glow.alpha = 0;
        this.glow.anchor.set(0.5);

        this.addChild(this.glow);
        this.addChild(this.number);
    }

    draw() {
        const tweenQueue = new PIXI.TweenQueue();

        tweenQueue.add(new PIXI.Tween().to({ alpha: 0.5 }).duration(10));
        tweenQueue.add(new PIXI.Tween().duration(500));
        tweenQueue.add(new PIXI.Tween().to({ alpha: 0 }).duration(10));
        tweenQueue.add(new PIXI.Tween().duration(500));

        this.glow.addTween(tweenQueue).loop();
        // this.glow.addTween().to({ alpha: 0.5 }).delay(1000).duration(300).ease(PIXI.TweenEasing.Exponential.Out).yoyo().loop();
    }

    reset() {
        this.glow.removeTweens();
        this.glow.alpha = 0;
    }
}