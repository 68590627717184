export default {
    paytable: [],
    matrix: [],
    slot_lines: [],
    fake_symbols: [0, 0, 0, 0, 0],
    scatters: [],
    scatter_symbol: -1,
    selected_lines: 0,
    symbols_without_wild: [],
    wild_multiplier: 1,
    wild_symbols: [],
    win: 0,
};
