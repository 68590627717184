export default class Viewport extends PIXI.Container {
    constructor(options = {}) {
        super();
        this._worldWidth = options.width;
        this._worldHeight = options.height;

        this._screenWidth = null;
        this._screenHeight = null;
    }

    resizeTo(width, height) {
        if (this._worldWidth / this._worldHeight >= width / height) {
            this.scale.set(height / this._worldHeight);
        } else {
            this.scale.set(width / this._worldWidth);
        }

        this.position.set(width / 2, height / 2);

        this._screenWidth = width;
        this._screenHeight = height;
    }

    translate(rect) {
        const { x, y } = this.scale;

        rect.x = (rect.x - this.x) / x;
        rect.y = (rect.y - this.y) / y;
        rect.width = rect.width / x;
        rect.height = rect.height / x;

        return rect;
    }
}
