export default {
    id: 'pixmove',
    initial: 'init',
    states: {
        init: {
            invoke: {
                id: 'initialize',
                src: 'initialize',
                onDone: 'preloading',
                onError: 'error',
            },
        },
        preloading: {
            invoke: {
                src: 'preload',
                onDone: 'loading',
                onError: 'error',
            }
        },
        loading: {
            invoke: {
                src: 'load',
                onDone: 'game',
                onError: 'error',
            }
        },
        game: {
            invoke: {
                id: 'game_service',
                src: 'game_service',
                onDone: 'exit',
                onError: 'error'
            },
        },
        exit: {
            type: 'final',
        },
        error: {
            type: 'final',
            entry: 'showError'
        }
    }
};
