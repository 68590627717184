import ContainerBuilder from './ContainerBuilder';

export default class NineSlicePlaneBuilder extends ContainerBuilder {
    type() {
        return 'nine_slice_plane';
    }

    create(json, resources) {
        if (typeof json.texture !== 'string')
            throw new Error(`nine_slice_plane ${json.name} does not have 'texture' field`);

        if (resources[json.texture] === undefined)
            throw new Error(`nine_slice_plane ${json.name}, cannot find texture ${json.texture}`);

        const { left = 15, top = 15, right = 15, bottom = 15 } = json;

        return new PIXI.NineSlicePlane(resources[json.texture].texture, left, top, right, bottom);
    }
}
