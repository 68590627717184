import Key from './Key';
import isMobile from './isMobile';

class Keyboard extends PIXI.Graphics {
    constructor() {
        super();

        this.visible = false;
        this._spacing = 18;
        this._keyboardHeight = 780;

        this.beginFill(0x272727).drawRect(-100, 0, 1280, 1200).endFill();

        const codes = [
            Key.CODE_1,
            Key.CODE_2,
            Key.CODE_3,
            Key.CODE_4,
            Key.CODE_5,
            Key.CODE_6,
            Key.CODE_7,
            Key.CODE_8,
            Key.CODE_9,
            Key.DOT,
            Key.CODE_0,
            Key.DEL,
        ];

        this._doneKey = new Key(Key.CLOSE);
        this._doneKey.resize(336, 168);
        this._doneKey.on('keydown', () => this.blur());

        this.addChild(this._doneKey);

        this._keys = [];
        for (let i = 0; i < codes.length; ++i) {
            const key = new Key(codes[i]);

            key.on('keydown', k => this.emit('keydown', k));
            this._keys.push(key);
            this.addChild(key);
        }

        this.keyDownHandler = this.onKeyDown.bind(this);
        this.prevInput = null;
    }

    focus() {
        if (!isMobile) {
            document.removeEventListener('keydown', this.keyDownHandler);
            document.addEventListener('keydown', this.keyDownHandler);
        }

        this.emit('focus');
    }

    blur() {
        if (this.prevInput)
            this.prevInput.blur();

        if (!isMobile)
            document.removeEventListener('keydown', this.keyDownHandler);

        this.emit('blur');
    }

    setInput(input) {
        if (this.prevInput)
            this.prevInput.blur();

        this.prevInput = input;
    }

    onKeyDown(e) {
        switch (e.code) {
        case 'Digit1':
            this.emit('keydown', Key.CODE_1);
            break;
        case 'Digit2':
            this.emit('keydown', Key.CODE_2);
            break;
        case 'Digit3':
            this.emit('keydown', Key.CODE_3);
            break;
        case 'Digit4':
            this.emit('keydown', Key.CODE_4);
            break;
        case 'Digit5':
            this.emit('keydown', Key.CODE_5);
            break;
        case 'Digit6':
            this.emit('keydown', Key.CODE_6);
            break;
        case 'Digit7':
            this.emit('keydown', Key.CODE_7);
            break;
        case 'Digit8':
            this.emit('keydown', Key.CODE_8);
            break;
        case 'Digit9':
            this.emit('keydown', Key.CODE_9);
            break;
        case 'Digit0':
            this.emit('keydown', Key.CODE_0);
            break;
        case 'Period':
            this.emit('keydown', Key.DOT);
            break;
        case 'Backspace':
            this.emit('keydown', Key.DEL);
            break;
        }
    }

    resize(width, height) {

        this.scale.set(width / 1080);

        let keyWidth = 336;
        let keyHeight = 168;
        let spacing = 18;
        let yOffset = 20;
        let radius = 30;
        let closeKeyHeight = keyHeight / 2;
        let closeKeyWidth = keyWidth / 2;
        let fontSize = 80;
        let closeFontSize = 40;

        if (height < width) {
            keyWidth = 250;
            keyHeight = 50;
            closeKeyHeight = 40;
            spacing = 10;
            yOffset = 13;
            radius = 12;
            fontSize = 30;
            closeFontSize = 25;
            this._keyboardHeight = 300;
        } else {
            this._keyboardHeight = 900;
        }


        const xOffset = (1080 - 3 * keyWidth - 2 * spacing) / 2;

        this._doneKey.resize(closeKeyWidth, closeKeyHeight, radius, closeFontSize);
        this._doneKey.position.set(1080 - closeKeyWidth - xOffset, yOffset);

        yOffset += closeKeyHeight + spacing;

        for (let i = 0; i < this._keys.length; ++i) {
            const key = this._keys[i];

            key.position.set(xOffset + (i % 3) * (keyWidth + spacing), yOffset + Math.floor(i / 3) * (keyHeight + spacing));
            key.resize(keyWidth, keyHeight, radius, fontSize);
        }
    }

    get height() {
        return this._keyboardHeight * this.scale.y;
    }
}

const keyboard = new Keyboard();

export default keyboard;
