import TweenManager from './TweenManager';
import TweenEasing from './TweenEasing';
import TweenQueue from './TweenQueue';
import Tween from './Tween';

(function() {
    PIXI.Tween = Tween;
    PIXI.TweenQueue = TweenQueue;
    PIXI.TweenEasing = TweenEasing;
    PIXI.TweenManager = TweenManager;

    PIXI.DisplayObject.prototype.addTween = function(tween = new PIXI.Tween()) {
        if (!tween)
            return null;

        tween.setTarget(this);

        TweenManager.addTween(tween);

        return tween;
    };

    PIXI.DisplayObject.prototype.removeTween = function(tween = null) {
        TweenManager.removeTween(tween);
    };

    PIXI.DisplayObject.prototype.removeTweens = function(callComplete = false) {
        TweenManager.removeTweens(this, callComplete);
    };
})();
