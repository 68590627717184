import ContainerBuilder from './ContainerBuilder';

export default class SpineBuilder extends ContainerBuilder {
    type() {
        return 'spine';
    }

    create(json, resources) {
        if (resources[json.spineData] === undefined)
            throw new Error(`Spine ${json.name}: not found spine data ${json.spineData} in resources`);

        return new PIXI.spine.Spine(resources[json.spineData]);
    }

    apply(target, json, resources) {
        super.apply(target, json, resources);

        if (typeof json.skin === 'string') {
            target.skeleton.setSkin(null);
            target.skeleton.setSkinByName(json.skin);
            target.skeleton.setSlotsToSetupPose();
        }

        if (typeof json.autoUpdate === 'boolean')
            target.autoUpdate = json.autoUpdate;

        if (typeof json.defaultMix === 'number')
            target.state.defaultMix = json.defaultMix;

        if (typeof json.anim === 'string')
            target.state.setAnimation(0, json.anim, !!json.loop);
    }
}
