import MusicPlayer from '../../music/MusicPlayer';

const noop = () => {};

export default class SlotColumn extends PIXI.Container {
    constructor() {
        super();

        this.mask = new PIXI.Graphics();
        this.addChild(this.mask);
        this.anims = [];
        this.fakeSymbol = 0;
        this.isGoingDown = true;

        this.afterSelectColumn = noop;
        this.alterColumnSprites = noop;
        this.alterColumnSprite = noop;
        this.spritePrototypes = [];
        this.columnSprites = [];

        this.spinTween = null;

        this.content = new PIXI.Container();
        this.content.name = 'content';
        this.addChild(this.content);
    }

    clip(width, height) {
        if (this.mask === null) {
            this.mask = new PIXI.Graphics();
            this.addChild(this.mask);
        }

        this.mask.clear()
            .beginFill(0xFF0000).drawRect(0, 0, width, height).endFill();
    }

    clearColumn() {
        this.content.removeTweens();
        this.content.removeChildren();
        this.content.y = 0;

        // TODO: Not sure if we need it
        // for (let i = 0, last = this.columnSprites.length; i < last; ++i)
        //     this.columnSprites[i].detach();

        this.columnSprites = [];
    }

    setAnims(anims) {
        this.anims = anims.slice();
    }

    setSpritePrototypesVector(spritePrototypes) {
        this.spritePrototypes = spritePrototypes.slice();
    }

    spin(cells, duration, stopSpinSound = '', fakeSymbol) {
        this.fakeSymbol = fakeSymbol;
        this.setColumn(cells);
        this.content.y = this.mask.height - this.content.height;

        this.spinTween = new PIXI.Tween().
              to({ y: 0 }).
              duration(duration).
              onComplete(() => {
                  if (stopSpinSound.length)
                      MusicPlayer.play(stopSpinSound);
              });

        const queue = new PIXI.TweenQueue();

        queue.add(this.spinTween);
        queue.add(new PIXI.Tween()).
            to({ y: this.mask.height * 0.06 }).
            duration(4 * 1000 /60).
            ease(PIXI.TweenEasing.Sinusoidal.Out);

        queue.add(new PIXI.Tween()).
            to({ y: 0 }).
            duration(5 * 1000 / 60).
            ease(PIXI.TweenEasing.Sinusoidal.In);

        return this.content.addTween(queue);
    }

    getSymbolSprite(row) {
        return this.columnSprites[row];
    }

    setColumn(cells, fakeSymbol) {
        this.content.removeChildren();

        let y = 0;
        for (let i = 0, last = cells.length; i < last; ++i) {
            const symbol = new PIXI.AnimatedSprite(this.anims[cells[i]]);

            symbol.y = y;
            y += symbol.height;
            this.content.addChild(symbol);
        }
    }

    setAnimForSymbol(symbol, anim) {
        if (symbol > this.anims.length || symbol < 0)
            return;

        this.anims[symbol] = anim;
    }
}
