class TweenManager {
    constructor() {
        this._tweens = [];
    }

    update(dt) {
        this._tweens.forEach(t => t.update(dt));
    }

    addTween(tween) {
        this._tweens.push(tween);
    }

    removeTween(tween) {
        if (!tween)
            return;

        let index = this._tweens.indexOf(tween);

        if (index !== -1) {
            this._tweens.splice(index, 1);
        }
    }

    removeTweens(target, callComplete = false) {
        const tweens = this.getTweensFor(target);

        tweens.forEach(t => {
            t.complete(callComplete);
        });
    }

    getTweensFor(target) {
        return this._tweens.filter(t => t._target === target);
    }
}

export default new TweenManager();;
