export default class MultiPackLoaderPlugin {
    static use(resource, next) {
        // because this is middleware, it execute in loader context. `this` = loader
	    var loader = this;
	    var imageResourceName = resource.name + "_image";
	    // skip if no data, its not json, it isn't spritesheet data, or the image resource already exists
	    if (!resource.data
	        // || resource.type !== LoaderResource.TYPE.JSON
	        || !resource.data.frames
            || !resource.data.meta
            || resource.type !== PIXI.LoaderResource.TYPE.JSON) {
	        next();
	        return;
	    }

        const multipacks = resource.data.meta.related_multi_packs;

        if (multipacks instanceof Array) {
            for (let i = 0; i < multipacks.length; ++i) {
                const item = multipacks[i];
                if (typeof item !== 'string') {
                    continue;
                }

                const itemName = item.replace('.json', '');
                const itemUrl = PIXI.utils.url.resolve(resource.url.replace(loader.baseUrl, ''), item);

                if (loader.resources[itemName] ||
                    Object.values(loader.resources).some((r) => PIXI.utils.url.format(PIXI.utils.url.parse(r.url)) === itemUrl)) {
                    continue;
                }

                const options = {
	                crossOrigin: resource.crossOrigin,
                    loadType: PIXI.LoaderResource.LOAD_TYPE.XHR,
                    xhrType: PIXI.LoaderResource.XHR_RESPONSE_TYPE.JSON,
                    parentResource: resource,
                };

                loader.add(itemName, itemUrl, options);
            }
        }

	    next();
    }
}
