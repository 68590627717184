class Localizer {
    constructor() {
        this.locale = {};
        this.default = {};
    }

    setDefault(locale) {
        this.default = locale;
    }

    setLocale(locale = {}) {
        this.locale = locale;
    }

    localize(locale, ...keys) {
        if (Object.keys(locale).length === 0)
            return '';

        for (let i = 0; i < keys.length; ++i) {
            if (locale[keys[i]] !== undefined) {
                locale = locale[keys[i]];
            } else {
                return '';
            }
        }

        return locale;
    }

    tr(...keys) {
        return this.localize(this.locale, ...keys) || this.localize(this.default, ...keys) || keys.join('.');
    }
}

const localizer = new Localizer();

export default localizer;
