import HistoryView from './HistoryView';
import StatsView from './StatsView';
import { drawHeaderStyle } from './TextStyles';
import TimerView from './TimerView';

export default {
    type: 'container',
    name: 'game_scene',
    children: [
        {
            type: 'sprite',
            name: 'background',
            texture: 'background',
            anchor: 0.5
        },
        {
            type: 'sprite',
            name: 'gates_container',
            texture: 'gates_background',
            x: -68,
            y: -13,
            anchor: 0.5,
            children: [
                {
                    type: 'sprite',
                    name: 'gates_net',
                    texture: 'gates_net',
                    anchor: 0.5
                },
                {
                    type: 'sprite',
                    name: 'gates_frame',
                    texture: 'gates_frame',
                    anchor: 0.5
                },
                {
                    type: 'sprite',
                    name: 'gates_glow',
                    texture: 'gates_glow',
                    alpha: 0,
                    anchor: 0.5
                }
            ]
        },
        {
            type: 'container',
            name: 'cells_container',
            x: -267.5,
            y: -76,
            // x: 56 y: 67
            children: []
        },
        TimerView,
        HistoryView,
        StatsView,
        {
            type: 'container',
            name: 'draw_number_container',
            x: -179,
            y: -165,
            children: [
                {
                    type: 'text',
                    name: 'draw_label',
                    text: 'ТИРАЖ',
                    scale: 0.5,
                    anchor: 0.5,
                    y: -9,
                    style: {
                        ...drawHeaderStyle,
                        fontSize: 15
                    }
                },
                {
                    type: 'text',
                    name: 'draw_number',
                    text: '№35167',
                    scale: 0.5,
                    anchor: 0.5,
                    style: {
                        ...drawHeaderStyle,
                        fontSize: 25
                    }
                },
            ]
        },
        {
            type: 'sprite',
            name: 'blueprint',
            texture: 'blueprint',
            anchor: 0.5,
            alpha: 0
        }
    ],
};
