const titleStyle = {

};

const timerStyle = {
    fontFamily: 'Roboto',
    fontSize: 15,
    fill: 0xFFFFFF,
};

const drawHeaderStyle = {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 'bold',
    fill: 0xFFFFFF,
};

const panelTitleStyle = {
    fontFamily: 'Roboto',
    fontSize: 15,
    fill: 0x01ACEB,
};

const panelDataStyle = {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 200,
    fill: 0x929392,
};

const cellNumberStyle = {
    fontFamily: 'Roboto',
    fontSize: 23,
    fill: 0xFFFFFF
};

const statsLabelsStyle = {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 200,
    fill: 0xFFFFFF
}

export {
    titleStyle,
    timerStyle,
    drawHeaderStyle,
    panelTitleStyle,
    panelDataStyle,
    cellNumberStyle,
    statsLabelsStyle
}