import EE from 'eventemitter3';
import Sound from './Sound';

class MusicPlayer extends EE {
    constructor() {
        super();

        this.resources = {};

        this.options = {
            muted: false,
            speed: 1,
            volume: 1,
        };
    }

    setResources(resources) {
        this.resources = resources;
    }

    getSound(name) {
        if (Object.hasOwnProperty.call(this.resources, name)) {
            return new Sound(this.resources[name], this.options);
        }

        console.warn(`MusicPlayer does not have resource with name: ${name}`);
        return null;
    }

    play(name, options = {}) {
        return this.getSound(name).play(options);
    }

    mute(mute = true) {
        this.options.muted = mute;
        return this;
    }

    volume(volume) {
        this.options.volume = volume;
        return this;
    }

    speed(speed) {
        this.options.speed = speed;
        return this;
    }
}

export default new MusicPlayer();
