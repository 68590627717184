import getSlotConfig from './slot_config';
import { free_slot_context } from '../context';

const free_game = {
    id: 'free_game',
    initial: 'show_free_game',
    states: {
        show_free_game: {
            invoke: {
                src: 'showFreeGame',
                onDone: 'wait_start',
            },
        },
        wait_start: {
            on: {
                SPIN: 'request_free_spin',
            }
        },
        request_free_spin: {
            invoke: {
                src: 'requestFreeSpin',
                onDone: 'free_spinning',
            }
        },
        free_spinning: {
            invoke: {
                src: 'freeSpin',
                onDone: 'show_free_win_anim',
            }
        },
        show_free_win_anim: {
            invoke: {
                src: 'showFreeWinAnim',
                onDone: 'show_retrigger_anim',
            }
        },
        show_retrigger_anim: {
            invoke: {
                src: 'showRetriggerAnim',
                onDone: [
                    { target: 'request_free_spin', cond: 'hasFreeSpins' },
                    { target: 'show_free_total_win' },
                ],
            }
        },
        show_free_total_win: {
            invoke: {
                src: 'showFreeTotalWin',
                onDone: 'take_free_win',
            }
        },
        take_free_win: {
            on: {
                SPIN: 'request_take_free_win',
            },
        },
        request_take_free_win: {
            invoke: {
                src: 'requestTakeFreeWin',
                onDone: 'show_take_free_win',
            }
        },
        show_take_free_win: {
            invoke: {
                src: 'show_take_free_win',
                onDone: 'hide_free_game',
            }
        },
        hide_free_game: {
            invoke: {
                src: 'hideFreeGame',
                onDone: 'exit',
            }
        },
        exit: { type: 'final' },
    },
    onDone: 'idle',
};

const freeGameOption = cfg => {
    const states = cfg.states.game.states;

    cfg.context = {
        ...cfg.context,
        ...free_slot_context
    };

    states.free_game = free_game;
    states.show_win_anim.invoke.onDone.unshift({ target: 'free_game', cond: 'hasFreeSpins' });
};

export default (...options) => {
    const config = getSlotConfig();

    freeGameOption(config);

    options.forEach(fn => fn(config));

    // console.log(config);

    return config;
}
