import EE from 'eventemitter3';
import TweenEasing from './TweenEasing';
import TweenManager from './TweenManager';

export default class TweenQueue extends EE {
    constructor() {
        super();

        this._playing = false;
        this._delay = 0;
        this._loop = 1;
        this._detach = false;
        this._target = null;
        this._current = -1;
        this._autoStart = true;
        this._resolve = null;
        this._reject = null;
        this._promise = null;

        this._tweens = [];
    }

    delay(delay) {
        this._delay = delay;
        return this;
    }

    loop(loop = -1) {
        this._loop = loop;
        return this;
    }

    detach(detach = true) {
        this._detach = detach;
        return this;
    }

    onComplete(onComplete) {
        this.on('complete', onComplete);
        return this;
    }

    setTarget(target) {
        this._target = target;

        this._tweens.forEach(t => {
            t.setTarget(this._target);
            t.on('complete', this.next, this);
        });

        return this;
    }

    autoStart(autoStart = true) {
        this._autoStart = autoStart;
    }

    add(tween) {
        this._tweens.push(tween);
        return tween;
    }

    next() {
        this._current++;
        if (this._current < this._tweens.length)
            this._tweens[this._current].start();
    }

    start() {
        if (this._playing)
            return this;

        this._playing = true;
        this.emit('started');
        return this;
    }

    stop() {
        if (!this._playing)
            return this;

        this._playing = false;
        this.emit('stopped');
        return this;
    }

    promise() {
        if (this._promise === null) {
            this._promise = new Promise((resolve, reject) => {
                this._resolve = resolve;
                this._reject = reject;
            });
        }

        return this._promise;
    }

    complete(complete = true) {
        if (!this._playing)
            return;

        if (this._detach && this._target && this._target.parent)
            this._target.parent.removeChild(this._target);

        this.stop();

        if (this._promise !== null) {
            if (complete)
                this._resolve();
            else
                this._reject();

            this._promise = null;
            this._resolve = null;
            this._reject = null;
        }

        this._tweens.forEach(t => t.complete(complete));

        if (complete)
            this.emit('complete', this);

        TweenManager.removeTween(this);
    }

    clear() {
        this._playing = false;
        this._delay = 0;
        this._loop = 1;
        this._detach = false;
        this._target = null;
        this._tweens = [];
    }

    reset() {
        this._current = 0;
        this._tweens.forEach(t => t.reset(true));
    }

    update(dt) {
        if (!this._playing) {
            if (this._autoStart) {
                this.start();
                this.next();
            } else {
                return;
            }
        }

        if (this._current < this._tweens.length) {
            this._tweens[this._current].update(dt);
        } else {
            this._loop--;

            if (!this._loop) {
                this.complete();
            } else {
                this.reset();
            }
        }
    }

    isPlaying() {
        return this._playing;
    }
}
