import {
    ContainerBuilder,
    SpriteBuilder,
    TilingSpriteBuilder,
    NineSlicePlaneBuilder,
    TextBuilder,
    SpineBuilder,
    ButtonBuilder,
    SwitchBuilder,
    InputBuilder,
    ScrollBoxBuilder,
    GraphicsBuilder,
    SlotColumnBuilder,
} from './builders';

export default class ViewBuilder {
    constructor() {
        this.builders = {};

        this.addBuilder(new ContainerBuilder())
            .addBuilder(new SpriteBuilder())
            .addBuilder(new TilingSpriteBuilder())
            .addBuilder(new NineSlicePlaneBuilder())
            .addBuilder(new TextBuilder())
            .addBuilder(new SpineBuilder())
            .addBuilder(new ButtonBuilder())
            .addBuilder(new SwitchBuilder())
            .addBuilder(new ScrollBoxBuilder())
            .addBuilder(new InputBuilder())
            .addBuilder(new GraphicsBuilder())
            .addBuilder(new SlotColumnBuilder());
    }

    addBuilder(builder) {
        if (this.builders[builder.type()]) {
            console.warn(`Builder type '${builder.type()}' already addded`);
            return this;
        }

        this.builders[builder.type()] = builder;

        return this;
    }

    build(json, resources) {
        let result = null;

        if (typeof json !== 'object')
            return result;

        if (typeof json.type === 'string') {
            if (this.builders[json.type]) {
                const builder = this.builders[json.type];

                result = builder.create(json, resources);

                if (json.children instanceof Array) {
                    for (let i = 0, last = json.children.length; i < last; ++i) {
                        const component = json.children[i];

                        const child = this.build(component, resources);

                        result.addChild(child);
                    }
                }

                builder.apply(result, json, resources);
            } else {
                console.error(`Unsupported component type: ${json.type}`);
            }
        } else {
            console.error(`Json does not containt 'type' field`);
        }

        return result;
    }
}
